<template>
  <div class="row justify-content-center py-5">
    <div class="col-md-8">

      <div class="text-center">
        <h4>Email Us</h4>
        <p class="text-muted">To send us an enquiry, kindly complete the form below and click on the "Submit" button. Our staff representative will respond promptly to your request.</p>
      </div>

      <b-form @submit="onSubmit" @reset="onReset" v-if="show">

        <b-form-group id="form-group-name" label="Fullname" label-for="name">
          <b-form-input
            id="name"
            v-model="form.name"
            placeholder="Enter your fullname"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="form-group-position" label="Position" label-for="position">
          <b-form-input
            id="position"
            v-model="form.position"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="form-group-company" label="Company" label-for="company">
          <b-form-input
            id="company"
            v-model="form.company"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>


        <b-form-group
          id="form-group-email"
          label="Email Address"
          label-for="email"
          description="We'll never share your email with anyone else."
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            placeholder="Enter your email address"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="form-group-telephone" label="Telephone" label-for="telephone">
          <b-form-input
            id="telephone"
            v-model="form.telephone"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="form-group-fax" label="Fax" label-for="fax">
          <b-form-input
            id="fax"
            v-model="form.fax"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="form-group-message" label="Your Message" label-for="message">
          <b-form-textarea
            id="message"
            v-model="form.message"
            placeholder="Enter your message here..."
            rows="6"
          ></b-form-textarea>
        </b-form-group>

        <div class="text-center">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>

      </b-form>
    </div>


  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          email: '',
          name: '',
          food: null,
          checked: []
        },
        foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
        show: true
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
  }
</script>