import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import LayoutDefault from './layouts/LayoutDefault'
import LayoutBlank from './layouts/LayoutBlank'

Vue.config.productionTip = false

// global variable
Vue.prototype.$axios = axios

import {STO_BASE_URL,  STO_API_SECRET}  from '../public/configuration.js'
Vue.prototype.$apiUrl = STO_BASE_URL;
Vue.prototype.$apiToken = STO_API_SECRET;

Vue.prototype.$globalData = {};



Vue.component('layout-default', LayoutDefault)
Vue.component('layout-blank', LayoutBlank)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
