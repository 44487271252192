<template>
  <div>
    <div class="row">
      <div class="col-md-3" v-for="article in articles" :key="article.id">
        <router-link :to="article.view_url" class="card-link">
          <b-card
            :title="article.title"
            :img-src="article.image"
            :img-alt="article.title"
            img-top
            class="card-article mb-2"
          >
          </b-card>
        </router-link>
      </div>
    </div>
    <div class="overflow-auto">
      <b-pagination-nav :number-of-pages="10" align="center" use-router></b-pagination-nav>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Articles',
    props: {
      section: Object
    },
    data () {
      return {
       articles: {}
      }
    },
    created () {
      // console.log(this.section);
      this.$axios
          .get(this.$apiUrl+this.section.view_url+'?page_limit=20&token='+this.$apiToken)
          .then(response => {
            this.articles = response.data.articles;
            // console.log(this.pageData);
          }).catch(error => {
            console.log('There was an error:' + error.response)
          })
    }
  }
</script>


<style scoped>

  .card-link {
    color: #212529;
  }

  .card-link:hover .card-title{
    text-decoration: underline;
  }

  .card-article {
    border-radius: 0px;
    border: none;
    margin-bottom: 2rem !important;
  }

  .card-article .card-img-top {
    border-radius: 0px;
    object-fit: cover;
    height: 300px; 
  }

  .card-article .card-body {
    padding: 15px 0px 15px 0px;
    height: 125px;
  }

  .card-article .card-title {
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.35em;
  }

  .card-article .card-text {

  }

</style>