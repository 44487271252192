<template>
  <div class="py-3">
    <h4>{{ content.title }}</h4>
    <div v-html="content.body">
    </div>
  </div>
</template>


<script>
  export default {
    name: 'Article',
    props: {
      content: Object
    }
  }
</script>