<template>
  <div class="home">

    <Carousel/>

    <CallToAction/>

    <Article/>

    <ItemFeatured/>

    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    


  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Carousel from '@/components/Carousel.vue'
import CallToAction from '@/components/CallToAction.vue'
import Article from '@/components/Article.vue'
import ItemFeatured from '@/components/ItemFeatured.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    Carousel,
    CallToAction,
    Article,
    ItemFeatured
  }
}
</script>
