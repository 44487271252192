<template>
  <footer class="footer mt-auto py-3 mt-5">
    <b-container fluid="xl">
      <b-row class="mt-5 mb-5">
        <b-col md="6">
          <div>
            <img src="https://placeholder.pics/svg/250x100/DEDEDE/555555/Nixser%20Cloud%20CMS" alt="" class="footer-logo" />
            <div class="footer-content">
              <h5 class="mt-4">Lorem ipsum dolor imet</h5>
              <p>
                <span>Block A-04-05, 1 Tebrau, Jln Seri Setanggi, Taman Seri Setanggi, 80250 Johor Bahru, Johor. Malaysia.</span><br>
                <span>+607 212 1960</span><br>
                <span>+607 212 1960</span><br>
                <a href="mailto:hello@nixser.com.my" class="text-primary">hello@nixser.com.my</a><br>
              </p>
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <b-row class="">
            <b-col class="md-3 mb-md-0 mb-3">
              <h6 class="text-uppercase">Menu Title</h6>
              <ul class="list-unstyled">
                <li>
                  <a href="#" class="text-white">Link 1</a>
                </li>
                <li>
                  <a href="#" class="text-white">Link 2</a>
                </li>
                <li>
                  <a href="#" class="text-white">Link 3</a>
                </li>
                <li>
                  <a href="#" class="text-white">Link 4</a>
                </li>
              </ul>
            </b-col>
            <b-col class="md-3 mb-md-0 mb-3">
              <h6 class="text-uppercase">Menu Title</h6>
              <ul class="list-unstyled">
                <li>
                  <a href="#" class="text-white">Link 1</a>
                </li>
                <li>
                  <a href="#" class="text-white">Link 2</a>
                </li>
                <li>
                  <a href="#" class="text-white">Link 3</a>
                </li>
                <li>
                  <a href="#" class="text-white">Link 4</a>
                </li>
              </ul>
            </b-col>
            <b-col class="md-3 mb-md-0 mb-3">
              <h6 class="text-uppercase">Menu Title</h6>
              <ul class="list-unstyled">
                <li>
                  <a href="#" class="text-white">Link 1</a>
                </li>
                <li>
                  <a href="#" class="text-white">Link 2</a>
                </li>
                <li>
                  <a href="#" class="text-white">Link 3</a>
                </li>
                <li>
                  <a href="#" class="text-white">Link 4</a>
                </li>
              </ul>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
      <div class="footer-divider my-3"></div>
      <b-row class="">
        <b-col md="12" class="text-center">
          <div class="list-inline mb-3">
            <a href="#" class="list-inline-item" title="#" target="_blank">
              <b-icon icon="facebook" variant="light" class="footer-social-icon"></b-icon>
            </a>
            <a href="#" class="list-inline-item" title="#" target="_blank">
              <b-icon icon="instagram" variant="light" class="footer-social-icon"></b-icon>
            </a>
            <a href="#" class="list-inline-item" title="#" target="_blank">
              <b-icon icon="twitter" variant="light" class="footer-social-icon"></b-icon>
            </a>
            <a href="#" class="list-inline-item" title="#" target="_blank">
              <b-icon icon="youtube" variant="light" class="footer-social-icon"></b-icon>
            </a>
          </div>
          <span class="footer-copyright text-muted">Copyright &copy; 2022 Nixser Cloud CMS. All rights reserved.</span>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  components: {Navbar},
  data () {
    return {
      footer: []
    }
  },
  created () {
    this.$axios
      .get(this.$apiUrl+'/footer?token='+this.$apiToken)
      .then(response => {
        this.footer = response.data;
        // console.log(this.footer);
      }).catch(error => {
        console.log('There was an error:' + error.response)
      })
  }
}

</script>


<style scoped>
  .footer {
    background-color: #000000;
    color: #fff;
  }

  .footer-logo {
	  height: 100px;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */    
  }

  .footer-content {
    font-size: 12px;
  }

  .footer-social-icon {
    color: #fff;
    font-size: 1.75em;
  }

  .footer-divider {
    border-bottom: 0.5px solid #dee2e666 !important;
  }

  .footer-copyright {
    font-size: .85em;
  }
</style>