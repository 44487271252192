<template>
  <div class="page">
    <div v-if="pageData.name == 'home'">
      <Section v-for="section in pageData.sections" :key="section.name" :section="section"/>
    </div>
    <div v-else>
      <b-container fluid="xl" class="pt-5">
        <h1 class="mb-3 text-center">{{ pageData.title }}</h1>
      </b-container>
      <b-container fluid="xl" class="py-5">
        <Section v-for="section in pageData.sections" :key="section.name" :section="section"/>
      </b-container>
    </div>
  </div>
</template>

<script>
  // if (typeof this.$route.params != 'undefined') {
  //   console.log(this.$route.params);
  // }



// @ is an alias to /src
import Section from '@/views/Section.vue'
// import HelloWorld from '@/components/HelloWorld.vue'
// import Carousel from '@/components/Carousel.vue'
// import CallToAction from '@/components/CallToAction.vue'
// import Article from '@/components/Article.vue'
// import ItemFeatured from '@/components/ItemFeatured.vue'

export default {
  name: 'Page',
  data () {
    return {
      page: this.$route.params.page,
      pageParam: '',
      pageData: '',
    }
  },
  watch: {
    '$route.params.page': function (page) {
      this.page = page;
      this.getPageContent()
    }
  },
  created () {
     this.getPageContent()
  },
  methods: {
    getPageContent() {
      if (typeof this.page !== 'undefined') {
        this.$axios
          .get(this.$apiUrl+'/find-view-url?link='+this.page+'&token='+this.$apiToken)
          .then(response => {
            this.pageParam = response.data.view_url;
            if (this.pageParam !== '') {
              this.$axios
                .get(this.$apiUrl+this.pageParam+'?token='+this.$apiToken)
                .then(response => {
                  this.pageData = response.data.page;
                  // console.log(this.pageData);
                }).catch(error => {
                  console.log('There was an error:' + error.response)
                })
            } else {
              console.log('404 not found');
            }

          }).catch(error => {
            console.log('There was an error:' + error.response)
          })         
      } else {
        this.$axios
          .get(this.$apiUrl+'/page/home?token='+this.$apiToken)
          .then(response => {
            this.pageData = response.data.page;
            // console.log(this.pageData);
          }).catch(error => {
            console.log('There was an error:' + error.response)
          })
      }
    }
  },
  components: {
    Section,
    // HelloWorld,
    // Carousel,
    // CallToAction,
    // Article,
    // ItemFeatured
  }
}
</script>
