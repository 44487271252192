<template>
  <div>
    <b-navbar toggleable="lg" variant="light" type="light" id="main-nav" class="bg-white shadow-sm">
      <!-- <b-navbar-brand href="#">Nixser Cloud CMS</b-navbar-brand> -->
      <b-navbar-brand href="#">
        <!-- <img src="https://placeholder.pics/svg/250x100/DEDEDE/555555/Nixser%20Cloud%20CMS" alt="" class="footer-logo" /> -->
        
        <img :src="header.brand_image_url" alt="" class="footer-logo" />
      </b-navbar-brand>



      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-for="link in header.links" :key="link.name"  v-bind:to="link.url">{{ link.name }}</b-nav-item>

          <b-nav-item-dropdown right class="dropdown-lang d-none">
            <template slot="button-content">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16">
                <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>
                <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/>
              </svg>
            </template>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">MY</b-dropdown-item>
            <b-dropdown-item href="#">ZH</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>   
</template>

<script>
export default {
  props: {
    header: Object
  }
}
</script>


<style scoped>
  .navbar-brand img {
    height: 65px;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */    
  }

  #main-nav .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .dropdown-lang .dropdown-toggle::after {
    content: none !important;
  }
</style>