<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
  const LayoutDefault = 'default'

  export default {
    name: 'App',
    computed: {
      layout() {
        return 'layout-' + (this.$route.meta.layout || LayoutDefault)
      }
    },
    data () {
      return {
        siteInfo: []
      }
    },
    created () {
      this.$axios
        .get(this.$apiUrl+'/site-info?token='+this.$apiToken)
        .then(response => {
          this.siteInfo = response.data;
          // console.log(this.siteInfo);
        }).catch(error => {
          console.log('There was an error:' + error.response)
        })
    }
  }
</script>

<style>
  html {
    height: 100%!important;
  }

  body {
    display: flex;
    flex-direction: column;
    height: 100%;   
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    height: 100%;
    /* text-align: center; */
    /* color: #2c3e50; */
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }

  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1440px !important;
    }
  }

  .gallery-img {
    object-fit: cover;
    width: 100%;
    height: 250px !important;
  }
</style>
